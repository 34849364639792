import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useObserver} from "mobx-react-lite";
import {Button, Dialog, DialogContent, DialogTitle, MenuItem, TextField} from "@mui/material";
import Typography from "@material-ui/core/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {useMediaQuery} from "@material-ui/core";
import axios from "axios";
import FormControl from "@material-ui/core/FormControl";
import Select from "@mui/material/Select";

const countryCodes = [
    { code: '82', label: 'Korea (+82)' },
    { code: '60', label: 'Malaysia (+60)' },
    { code: '62', label: 'Indonesia (+62)' },
    { code: '65', label: 'Singapore (+65)' },
    { code: '1', label: 'USA (+1)' },
    { code: '64', label: 'NewZealand (+64)' },
];

//커스텀 style 지정
const findIdStyle = makeStyles((theme) => ({
    dialog: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-around',
        '& .css-1jh78f5-MuiPaper-root-MuiDialog-paper.MuiDialog-paperScrollBody': {
            backgroundColor: theme.palette.secondary.main,
            border: '1px solid #171E31',
            borderRadius: 5,
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.35)'
        },
    },
    dialogHeader: {
        backgroundColor: theme.palette.secondary.main,
        height: 60,
        '& .css-i4bv87-MuiSvgIcon-root': {
            width: 27,
            height: 25
        }
    },
    dialog_content: {
        padding: "15px 30px 30px 30px !important",
        backgroundColor: '#fff',
        wordBreak: "keep-all"
    },
    title: {
        color: theme.palette.text.primary,
        ...theme.typography.h5,
        "& > *": {
            color: theme.palette.text.white,
            fontSize: "1.125rem"
        }
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: 10,
        "& > *": {
            color: theme.palette.text.primary,
        },
        '& > div:first-child': {
            minWidth: 130
        },
        [theme.breakpoints.down('sm')]: {
            overflowX: 'scroll'
        }

    },
    headerInfo: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        flexFlow: 'wrap',
        '& > div': {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            minHeight: 50,
            borderBottom: '1px solid #DBDADA',
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                borderBottom: 'none',
            },
            '& > dl': {
                display: 'flex',
                alignItems: 'center',
                minWidth: 295,
                margin: "8px 0",
                '& > *': {
                    fontFamily: ['Gmarket Sans', 'Noto Sans KR', 'sans-serif']
                },
                '& > dt': {
                    color: theme.palette.primary.main,
                    fontSize: '0.688rem',
                    fontWeight: 700
                },
                '& > dd': {
                    fontSize: '14px',
                    color: '#555',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    marginRight: theme.spacing(1),
                    marginInlineStart: theme.spacing(1),
                },
                [theme.breakpoints.down('xs')]: {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    borderBottom: '1px solid #DBDADA',
                    padding: '8px 0',
                    margin: 0,
                    minWidth: 100,
                    '& span': {
                        fontSize: '0.75rem'
                    },
                    '& > dd': {
                        marginInlineStart: 0
                    }
                }
            }
        },
        '& > :last-child': {
            borderBottom: 'none',
        }
    },


}));

/*
    상세 dialog 구성
    open : dialog 호출 여부
    oncloseEvent : 닫기 이벤트
    content : 테이블 row 값
 */
export default function FindUserKey({open, onCloseEvent}) {

    //호출 여부가 false면 열리지 않는다.
    if (!open) {
        return null;
    }

    const apiUrl = process.env.REACT_APP_BASE_URL !== null ? process.env.REACT_APP_BASE_URL : 'https://lab.fnsvalue.co.kr';
    const clientKey = (process?.env?.REACT_APP_MASTER_CLIENT_KEY !== null ? process.env.REACT_APP_MASTER_CLIENT_KEY : '1daec78593a643e6b53ce9803ded5916');

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));  //mobile 여부
    const {t} = useTranslation();
    const classes = findIdStyle();  //커스텀 Style

    const [emailOrPhoneNumber, setEmailOrPhoneNumber] = useState("");
    const [isEmailSelected, setIsEmailSelected] = useState(true);
    const [shouldShowOTPButton, setShouldShowOTPButton] = useState(false);
    const [shouldShowOTPVerify, setShouldShowOTPVerify] = useState(false);
    const [otp, setOTP] = useState(""); // OTP 입력값 상태
    const [userId, setUserId] = useState(""); // 사용자 ID 상태
    const [userName, setUserName] = useState(""); // 사용자 이름 상태
    const [selectedCountryCode, setSelectedCountryCode] = useState(countryCodes[0].code);

    const handleCountryCodeChange = (event) => {
        setSelectedCountryCode(event.target.value);
    };

    const handleEmailOrPhoneNumberChange = (event) => {
        setEmailOrPhoneNumber(event.target.value);
    };

    const handleOptionChange = (option) => {
        // 이메일 또는 휴대전화 번호 선택 여부를 업데이트합니다.
        setIsEmailSelected(option === 'email');
        setShouldShowOTPButton(false);
        setShouldShowOTPVerify(false);
        setOTP("");
        setEmailOrPhoneNumber("");
        setUserId("");
        setUserName("");
    };

    const handleUserInfoConfirmation = () => {
        // verifyType 설정
        let verifyType = isEmailSelected ? "CMMDUP001" : "CMMDUP002";
        let verifyData = emailOrPhoneNumber;

        // verifyType이 CMMDUP002인 경우 처리
        if (verifyType === "CMMDUP002" && selectedCountryCode) {
            // 입력된 휴대전화 번호에서 맨 앞의 숫자를 제거하고 국가 코드를 앞에 추가합니다.
            verifyData = selectedCountryCode + verifyData.slice(1);
        }

        // API 요청을 보내는 부분
        axios.get(`${apiUrl}/api/v3/users/common/duplicate-check`, {
            params: {
                verifyData: verifyData,
                verifyType: verifyType
            }
        })
            .then(response => {
                // 요청이 성공하면 응답을 처리합니다.
                console.log("API 응답:", response.data);
                const rtCode = response.data.rtCode; // API 응답에서 rtCode 가져오기
                // rtCode 값에 따라 shouldShowOTPButton 상태 설정
                if (rtCode === 2019) {
                    setShouldShowOTPButton(true);
                } else {
                    setShouldShowOTPButton(false);
                    // 에러 메시지 출력
                    alert(t('FindUserKey.NoUserInfo'));
                }
            })
            .catch(error => {
                // 요청이 실패하면 에러를 처리합니다.
                console.error("API 요청 에러:", error);
                // shouldShowOTPButton 상태를 false로 설정
                setShouldShowOTPButton(false);
                // 에러 메시지 출력
                alert(t('FindUserKey.NoUserInfo'));
                // 기타 필요한 동작 수행
            });
    };




    const handleSendOTP = () => {
        // verifyType 설정
        const verifyType = isEmailSelected ? "CMMDUP001" : "CMMDUP002";
        const verifyData = emailOrPhoneNumber;

        // JSON 형식으로 request parameter 구성
        let requestData = {
            clientKey: clientKey,
        };

        // verifyType 값에 따라 request parameter에 email 또는 sms 속성 추가
        if (verifyType === "CMMDUP001") {
            requestData = {
                ...requestData,
                email: verifyData
            };
        } else if (verifyType === "CMMDUP002") {
            const phoneNumber = selectedCountryCode + verifyData.slice(1);
            requestData = {
                ...requestData,
                phoneNum: phoneNumber
            };
        }

        // API 요청 주소 설정
        const apiEndpoint = verifyType === "CMMDUP001" ? "/api/v3/mail" : "/api/v3/sms";

        // API 요청을 보내는 부분
        axios.post(`${apiUrl}${apiEndpoint}`, requestData)
            .then(response => {
                // 성공적으로 API 요청을 보낸 경우의 동작
                console.log("API 응답:", response.data);
                const rtCode = response.data.rtCode; // API 응답에서 rtCode 가져오기
                if (rtCode === 0) {
                    // rtCode 값이 0인 경우 OTP 입력 창을 활성화합니다.
                    setShouldShowOTPVerify(true);
                } else {
                    // rtCode 값이 0이 아닌 경우 에러 처리 또는 다른 동작 수행
                    console.log("API 요청 성공, 하지만 rtCode가 0이 아닙니다.");
                }
            })
            .catch(error => {
                // 요청이 실패하면 에러를 처리합니다.
                console.error("API 요청 에러:", error);
                // 기타 필요한 동작 수행
            });
    };





    const handleVerifyOTP = () => {
        // verifyType 설정
        const verifyType = isEmailSelected ? "CMMDUP001" : "CMMDUP002";
        let verifyData = emailOrPhoneNumber;
        const authNum = otp;

        // verifyType이 CMMDUP002인 경우 처리
        if (verifyType === "CMMDUP002") {
            verifyData = selectedCountryCode + verifyData.slice(1);
        }

        // JSON 형식으로 request parameter 구성
        let requestData = {
            clientKey: clientKey,
        };

        // verifyType 값에 따라 request parameter에 email 또는 sms 속성 추가
        if (verifyType === "CMMDUP001") {
            requestData = {
                ...requestData,
                email: verifyData,
                authNum: authNum // OTP 입력 값 추가
            };
        } else if (verifyType === "CMMDUP002") {
            requestData = {
                ...requestData,
                phoneNum: verifyData,
                authNum: authNum // OTP 입력 값 추가
            };
        }

        // API 요청 주소 설정
        const apiEndpoint = verifyType === "CMMDUP001" ? "/api/v3/mail/verify" : "/api/v3/sms/verify";

        // API 요청을 보내는 부분
        axios.post(`${apiUrl}${apiEndpoint}`, requestData)
            .then(response => {
                // 성공적으로 API 요청을 보낸 경우의 동작
                console.log("API 응답:", response.data);
                const rtCode = response.data.rtCode; // API 응답에서 rtCode 가져오기
                if (rtCode === 0) {
                    // rtCode 값이 0인 경우 OTP 입력 창을 활성화합니다.
                    console.log("API 요청 성공");
                    const disposeToken = response.data.data.disposeToken; // disposeToken 변수에 할당
                    console.log(disposeToken)
                    // 이제 disposeToken을 이용하여 findUserKey API를 호출합니다.
                    axios.get(`${apiUrl}/api/v3/findUserKey`, {
                        params: {
                            disposeToken: disposeToken,
                            verifyData: verifyData,
                            verifyType: verifyType
                        }
                    })
                        .then(response => {
                            // 성공적으로 추가 API 요청을 보낸 경우의 동작
                            console.log("추가 API 응답:", response.data);
                            // 응답으로 받은 userKey와 name 값을 userId와 userName 상태에 할당하여 표시
                            setUserId(response.data.data.userKey);
                            setUserName(response.data.data.name);
                        })
                        .catch(error => {
                            // 요청이 실패하면 에러를 처리합니다.
                            console.error("추가 API 요청 에러:", error);
                            // 기타 필요한 동작 수행
                        });
                } else {
                    // rtCode 값이 0이 아닌 경우 에러 처리 또는 다른 동작 수행
                    console.log("API 요청 성공, 하지만 rtCode가 0이 아닙니다.");
                    alert(t('FindUserKey.WrongOTP'));
                }
            })
            .catch(error => {
                // 요청이 실패하면 에러를 처리합니다.
                console.error("API 요청 에러:", error);
                // 기타 필요한 동작 수행
            });
    };

    const [openOtpPage, setOpenOtpPage] = useState(false);

    let ht = isMobile ? "100%" : "763";

    return useObserver(() =>

        <Dialog className={classes.dialog} open={open} onClose={onCloseEvent} maxWidth={"lg"}>
            <DialogTitle className={classes.dialogHeader}>
                <Typography className={classes.title}>
                    <span>{t("FindUserKey.Title")}</span>
                </Typography>
                {onCloseEvent ? (
                    <IconButton
                        aria-label="close"
                        onClick={onCloseEvent}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: "#fff",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent className={classes.dialog_content}>
                <div className={classes.headerContainer}>
                            <div style={{ fontSize: "16px", marginBottom: "0px" }}>
                                {t("FindUserKey.UserVerification")}
                            </div>
                </div>
                <div>
                    <div>
                        {/* OTP 인증 방법 선택 */}
                        <Typography variant="body1" style={{
                            fontSize: "16px",
                            marginBottom: '8px',
                            marginRight: '8px',
                            display: 'inline-block'
                        }}>
                            {t("FindUserKey.OTPVerificationMethod")}
                        </Typography>
                        <Button
                            variant={isEmailSelected ? "contained" : "outlined"}
                            color="primary"
                            onClick={() => handleOptionChange('email')}
                            style={{marginRight: '8px'}}
                        >
                            {t("FindUserKey.email")}
                        </Button>
                        <Button
                            variant={!isEmailSelected ? "contained" : "outlined"}
                            color="primary"
                            onClick={() => handleOptionChange('phoneNumber')}
                        >
                            {t("FindUserKey.sms")}
                        </Button>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "baseline", marginBottom: '16px'}}>
                        {!isEmailSelected && (
                            <FormControl sx={{minWidth: "30%", marginRight: "16px"}}>
                                <Select
                                    labelId="country-code-label"
                                    id="country-code"
                                    value={selectedCountryCode}
                                    onChange={handleCountryCodeChange}
                                    displayEmpty
                                    autoWidth
                                    renderValue={(selected) => {
                                        if (!selected) return '';
                                        const selectedCountry = countryCodes.find(country => country.code === selected);
                                        return selectedCountry ? `+${selectedCountry.code}` : '';
                                    }}
                                    sx={{width: '80px'}} // 넓이를 고정으로 설정
                                >
                                    {countryCodes.map((country) => (
                                        <MenuItem key={country.code} value={country.code}>
                                            {`${country.label}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}

                        <TextField
                            id="email-or-phone-number"
                            label={isEmailSelected ? t("FindUserKey.email") : t("FindUserKey.sms")}
                            variant="outlined"
                            value={emailOrPhoneNumber}
                            onChange={handleEmailOrPhoneNumberChange}
                            fullWidth
                            margin="normal"
                            sx={{flexGrow: 1, marginLeft: isEmailSelected ? '0' : '16px'}} // marginLeft 추가하여 간격 조절
                        />
                    </div>


                </div>
                {/* 사용자 정보 확인 버튼 */}
                <Button variant="contained"
                        color="primary"
                        onClick={handleUserInfoConfirmation}
                        style={{marginRight: '8px'}}
                >
                    {t("FindUserKey.userInfoCheck")}
                </Button>
                {/* OTP 발송 버튼 */}
                <Button variant="contained"
                        color="primary"
                        onClick={handleSendOTP}
                        style={{display: shouldShowOTPButton ? "inline-block" : "none"}} // shouldShowOTPButton 상태에 따라 버튼 표시 여부 결정
                >
                    {t("FindUserKey.sendOTP")}
                </Button>

                {/* OTP 입력 창 */}
                <TextField
                    id="otp"
                    label={t("FindUserKey.enterOTP")}
                    variant="outlined"
                    value={otp}
                    onChange={(event) => setOTP(event.target.value)}
                    fullWidth
                    margin="normal"
                    style={{
                        display: shouldShowOTPVerify ? "inline-block" : "none",
                        marginRight: '8px'
                    }} // shouldShowOTPButton 상태에 따라 입력 창 표시 여부 결정
                />
                {/* OTP 검증 버튼 */}
                <Button variant="contained"
                        color="primary"
                        onClick={handleVerifyOTP}
                        style={{ display: shouldShowOTPVerify ? "inline-block" : "none" }} // shouldShowOTPButton 상태에 따라 버튼 표시 여부 결정
                >
                    {t("FindUserKey.verifyOTP")}
                </Button>
                {/* 사용자 ID와 이름 표시 */}
                <Typography variant="body1" style={{fontSize: "16px", marginTop: '8px', display: (userId && shouldShowOTPVerify) ? 'block' : 'none'}}>
                    {t("FindUserKey.userId")}: {userId}
                </Typography>
                <Typography variant="body1" style={{fontSize: "16px", marginTop: '8px', display: (userName && shouldShowOTPVerify) ? 'block' : 'none'}}>
                    {t("FindUserKey.userName")}: {userName}
                </Typography>

            </DialogContent>
        </Dialog>


    )
}
